import store from "@/store";
import globalToast from '@/composables/common/globalToast';
import usersTable from '@/composables/users/fetchUserTable';

const userUpdation = () => {
    const { showGlobalToast } = globalToast();
    const { getUsers } = usersTable();

    const updateUser = (uservalues: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'users',
            request: 'updateuser',
            validateusername: username,
            validatepassword: password,
            uservalues
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            
            if(responseObj.requestStatus == 'success') {
                showGlobalToast('User Updated...');
                getUsers();
            } else {
                showGlobalToast('Error Updating User...');
            }
            
            ws.close();
        });
        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }
    
    return { updateUser };
}

export default userUpdation;