import store from "@/store";
import globalToast from '@/composables/common/globalToast';
import usersTable from '@/composables/users/fetchUserTable';

const userAddition = () => {
    const { showGlobalToast } = globalToast();
    const { getUsers } = usersTable();

    const addUser = (newusername: any, newpassword: any, newmobilenumber: any, newdescription: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'users',
            request: 'newregistration',
            validateusername: username,
            validatepassword: password,
            newusername: newusername,
            newpassword: newpassword,
            mobilenumber: newmobilenumber,
            description: newdescription
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });

        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);
            
            if(responseObj.requestStatus == 'success') {
                showGlobalToast('User Added...');
                getUsers();
            } else {
                showGlobalToast('Error Adding User...');
            }
            
            ws.close();
        });
        ws.addEventListener('open', (event) => {
            ws.send(Buffer.from(JSON.stringify(requestObj)).toString('base64'));
        });
    }
    
    return { addUser };
}

export default userAddition;