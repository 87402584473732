
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import './Users.scss';

import usersTable from '@/composables/users/fetchUserTable';
import userAddition from '@/composables/users/userAddition';
import userUpdation from '@/composables/users/userUpdation';
import userDeletion from '@/composables/users/userDeletion';

export default defineComponent({
    setup() {
        const { getUsers } = usersTable();
        const { addUser } = userAddition();
        const { updateUser } = userUpdation();
        const { removeUser } = userDeletion();

        const u_users = computed(() => store.getters.u_users);
        const u_roles = computed(() => store.getters.u_roles);

        const jurisdictions = computed(() => [...store.getters.g_districtsList, 'ALL']);

        const newusername = ref('');
        const newpassword = ref('');
        const newmobilenumber = ref('');
        const newdescription = ref('');

        const addUserWithInfo = () => {
            if(newusername.value == '' || newpassword.value == '') return;

            addUser(newusername.value, newpassword.value, newmobilenumber.value, newdescription.value);
            newusername.value = '';
            newpassword.value = '';
            newmobilenumber.value = '';
            newdescription.value = '';
        }

        const callUpdateUser = (e: any) => {
            let grandParent = e.target.parentNode.parentNode;

            let inputEls = grandParent.getElementsByClassName("inputel");
            // console.log(inputEls);

            let userValues = [];
            for(let i = 0; i < inputEls.length; i++){
                let el = inputEls[i];
                switch(el.nodeName){
                    case 'DIV':
                        userValues.push(el.innerText);
                        break;
                    default:
                        userValues.push(el.value);
                }
            }
            
            // console.log(userValues);
            updateUser(userValues);
        }

        const callDeleteUser = (e: any) => {
            let user = e.target.getAttribute('user');

            removeUser(user);
        }

        return { 
            u_users, u_roles, jurisdictions,
            getUsers, addUserWithInfo, newusername, newpassword, newmobilenumber, newdescription,
            callUpdateUser, callDeleteUser
        };
    },
})
